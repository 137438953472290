import React from "react";
import { NavLink } from "react-router-dom";
import "./Sidebar.css";
import "../../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faHouse,
	faFileLines,
	faDatabase,
	faCalendarWeek,
} from "@fortawesome/free-solid-svg-icons";

function Sidebar() {
	return (
		<div>
			<div className="iq-sidebar">
				<div className="iq-sidebar-logo  my-2 text-center">
					<span>Invoice Extraction</span>
					{/* <div className="img">
						<img
							src="Images/invoiceExtraction_img.png"
							className="img-fluid mb-4"
							alt="logo"
						/>
					</div> */}
					<div className="iq-menu-bt-sidebar">
						<div className="iq-menu-bt align-self-center">
							<div className="wrapper-menu">
								<div className="main-circle">
									<i className="ri-more-fill"></i>
								</div>
								<div className="hover-circle">
									<i className="ri-more-2-fill"></i>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					id=""
					data-scrollbar="true"
					tabIndex="-1"
					style={{ overflow: "hidden", outline: "none" }}>
					<div
						className="scroll-content"
						style={{ transform: "translate3d(0px, 0px, 0px)" }}>
						<nav className="iq-sidebar-menu">
							<ul className="iq-menu mt-4">
								{/* <li>
									<NavLink to="/dashboard">
										<FontAwesomeIcon icon={faHouse} />
										<span>Dashboard</span>
									</NavLink>
								</li> */}
								<li>
									<NavLink to="/input-file" activeclassname="active">
										<FontAwesomeIcon icon={faFileLines} />{" "}
										<span>Upload File</span>
									</NavLink>
								</li>
								<li>
									<NavLink to="/output-file" activeclassname="active">
										<FontAwesomeIcon icon={faCalendarWeek} />{" "}
										<span>List Of Documents</span>
									</NavLink>
								</li>
								{/* <li>
									<NavLink to="/stored-file" activeclassname="active">
										<FontAwesomeIcon icon={faDatabase} />
										<span>Stored File</span>
									</NavLink>
								</li> */}
								<li>
									<NavLink to="/AnalyzeDocument" activeclassname="active">
										<FontAwesomeIcon icon={faDatabase} />
										<span>Analyze Document</span>
									</NavLink>
								</li>
							</ul>
						</nav>
						<div className="p-3"></div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Sidebar;
