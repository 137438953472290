import React from "react";
import { Table, Button } from "antd";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { saveAs } from "file-saver";
import "./DashboardAll.css";

const OutputFile = () => {
	const responseData = useSelector((state) => state.responseData);
	const dataSource = responseData ? responseData.invoices_data || [] : [];
	const columns = [
		{
			title: "PDF Name",
			dataIndex: "pdf_name",
			key: "pdf_name",
			render: (text, record) => (
				<a
					href={`https://mediscan-api.brainvire.net/${record.pdf_path}`}
					target="_blank"
					rel="noreferrer">
					{text}
				</a>
			),
			width: 150,
		},

		{
			title: "Invoice Number",
			dataIndex: "invoice_no",
			key: "invoice_no",
			width: 150,
		},
		{
			title: "PO Number",
			dataIndex: "po_number",
			key: "po_number",
			width: 150,
		},
		{
			title: "Invoice Date",
			dataIndex: "invoice_date",
			key: "invoice_date",
			width: 150,
		},
		{
			title: "Seller GSTIN",
			dataIndex: "s_gstin",
			key: "s_gstin",
			width: 150,
		},
		{
			title: "Buyer GSTIN",
			dataIndex: "b_gstin",
			key: "b_gstin",
			width: 150,
		},
		{
			title: "Total Amount",
			dataIndex: "total_amount",
			key: "total_amount",
			width: 150,
		},
	];
	const exportTableToExcel = () => {
		// Define custom headings
		const customHeadings = [
			"PDF Name",
			"Invoice Number",
			"PO Number",
			"Invoice Date",
			"Seller GSTIN",
			"Buyer GSTIN",
			"Total Amount",
		];

		// Map dataSource to exportData with custom headings
		const exportData = dataSource.map((item) => {
			const row = {};
			columns.forEach((column, index) => {
				row[customHeadings[index]] = item[column.dataIndex];
			});
			return row;
		});

		// Convert to worksheet
		const ws = XLSX.utils.json_to_sheet(exportData);

		// Create workbook and append the worksheet
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

		// Convert workbook to buffer
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

		// Save the file
		saveAs(
			new Blob([excelBuffer], { type: "application/octet-stream" }),
			`invoice.xlsx`
		);
	};

	// const exportTableToExcel = () => {
	// 	const exportData = dataSource.map((item) => {
	// 		const row = {};
	// 		columns.forEach((column) => {
	// 			row[column.dataIndex] = item[column.dataIndex];
	// 		});
	// 		return row;
	// 	});

	// 	const ws = XLSX.utils.json_to_sheet(exportData);
	// 	const wb = XLSX.utils.book_new();
	// 	XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
	// 	const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

	// 	saveAs(
	// 		new Blob([excelBuffer], { type: "application/octet-stream" }),
	// 		`invoice.xlsx`
	// 	);
	// };

	return (
		<>
			<div className="working__area close__sidebar ">
				<div className="white_bg_sec">
					<div className="head d-flex justify-content-between">
						<h3 className="my-2">List Of Documents </h3>
						<Button className="ant-btn-primary " onClick={exportTableToExcel}>
							Export to Excel
							<FontAwesomeIcon className="mx-2" icon={faFileExcel} />
						</Button>
					</div>

					<Table
						dataSource={dataSource}
						columns={columns}
						scroll={{ x: 1100, y: 1000 }}
					/>
				</div>
			</div>
		</>
	);
};

export default OutputFile;
