import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./DashboardAll.css";

function AnalyzeDocument() {
	const [currentIndex, setCurrentIndex] = useState(0);

	const responseData = useSelector((state) => state.responseData);
	const pdfData = responseData?.invoices_data || [];

	const handleNext = () => {
		setCurrentIndex((prevIndex) => (prevIndex + 1) % pdfData.length);
	};

	const handlePrevious = () => {
		setCurrentIndex((prevIndex) =>
			prevIndex === 0 ? pdfData.length - 1 : prevIndex - 1
		);
	};

	return (
		<>
			<div className="working__area close__sidebar">
				<div className="analyze_doc mt-4">
					<h3>Analyze Document</h3>
					<p>
						The "Analyze Document" feature provides a concise summary of textual
						content, identifying key themes, patterns, and insights. It aids in
						extracting relevant information efficiently, offering a structured
						overview for quick comprehension and decision-making.
					</p>
					<div className="row">
						<div className="col-md-6">
							<div className="white_bg_sec">
								{pdfData.length > 0 ? (
									<>
										<div>
											<iframe
												src={`https://mediscan-api.brainvire.net/${pdfData[currentIndex]?.pdf_path}`}
												title="PDF Viewer"
												className="PDF_view"
												width="100%"
												height="500px"
												frameBorder="0"></iframe>
										</div>
										<div>
											Viewing PDF {currentIndex + 1} of {pdfData.length}
										</div>
										<div className="d-flex justify-content-between">
											<button
												className="ant-btn-primary Previous"
												onClick={handlePrevious}>
												Previous
											</button>
											<button
												className="ant-btn-primary Next"
												onClick={handleNext}>
												Next
											</button>
										</div>
									</>
								) : (
									<>
										<div className="img">
											<img
												src="Images/no_pdf.jpg"
												className="img-fluid mb-4"
												alt="logo"
											/>
										</div>
										<div className="noData_div">No PDFs available</div>
									</>
								)}
							</div>
						</div>
						<div className="col-md-6">
							<div className="white_bg_sec">
								{pdfData.length > 0 ? (
									<div className="text-start invoice__details">
										<h3 className="background-heading">
											Extracted Information
										</h3>
										<br></br>
										<p>
											<span>Invoice Name:</span>{" "}
											{pdfData[currentIndex]?.pdf_name}
										</p>
										<p>
											<span>Invoice No:</span>{" "}
											{pdfData[currentIndex]?.invoice_no}
										</p>
										<p>
											<span>Invoice Date:</span>{" "}
											{pdfData[currentIndex]?.invoice_date}
										</p>
										<p>
											<span>Seller GSTIN:</span>{" "}
											{pdfData[currentIndex]?.s_gstin}
										</p>
										<p>
											<span>Buyer GSTIN:</span> {pdfData[currentIndex]?.b_gstin}
										</p>
										<p>
											<span>Total Amount:</span>{" "}
											{pdfData[currentIndex]?.total_amount}
										</p>
									</div>
								) : (
									<>
										<div className="img">
											<img
												src="Images/no_data.jpg"
												className="img-fluid mb-4"
												alt="logo"
											/>
										</div>
										<div className="noData_div">No Data available</div>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default AnalyzeDocument;
