import "../../App.css";
import axios from "axios";
import React, { useState } from "react";
import { Upload, Button, message, Spin, Progress } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { apiEndPoints } from "../../constants/apiEndPoints";
import { useDispatch, useSelector } from "react-redux";
import { uploadFileSuccess, updateFileList } from "../../Action/actions";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function InputFile() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [file, setFileList] = useState([]);
	const [uploading, setUploading] = useState(false);
	const [progress, setProgress] = useState(0);

	const handleUpload = async () => {
		try {
			setUploading(true);

			const token = localStorage.getItem("token");
			const response = await axios.post(apiEndPoints.input_file, null, {
				headers: {
					Authorization: `Token ${token}`,
					"Content-Type": "application/json",
				},

			});

			dispatch(updateFileList([]));
			dispatch(uploadFileSuccess(response.data));

			message.success("Files Fetched successfully");
			setFileList([]);

			navigate("/output-file");
			console.log(response, "response-------------------");
		} catch (error) {
			console.error("Error occurred:", error);

			let errorMessage =
				"An error occurred while processing your request. Please try again later.";

			if (
				error.response &&
				error.response.data &&
				error.response.data.message
			) {
				errorMessage = error.response.data.message;
			}
			setProgress(0);

			message.error(errorMessage);
		} finally {
			setUploading(false);
		}
	};


	return (
		<>
			<div className="working__area close__sidebar ">
				<div className="white_bg_sec">
					<h3>Invoice Extraction</h3>
					<p>
						Extract data accurately from any image or pdf document in any layout
						without any manual setup. Our deep learning data extraction
						technology immensely reduces manual errors and saves countless hours
						every month.
					</p>

					<div>
						<Button
							type="primary"
							className="upload_btn"
							onClick={handleUpload}
							disabled={uploading}
							style={{ marginTop: 16 }}>
							{uploading ? <Spin /> : "Fetch"}
						</Button>


					</div>
				</div>
			</div>
		</>
	);
}
