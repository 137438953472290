// reducers.js
const initialState = {
	fileList: [],
	responseData: null,
};

const fileReducer = (state = initialState, action) => {
	switch (action.type) {
		case "UPLOAD_FILE_SUCCESS":
			return {
				...state,
				responseData: action.payload,
			};
		case "UPDATE_FILE_LIST":
			return {
				...state,
				fileList: action.payload,
			};
		default:
			return state;
	}
};

export default fileReducer;
