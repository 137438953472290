import React, { useState } from "react";
import axios from "axios";
import "./Login.css";
import InputField from "../Component/InputField";
import { NavLink } from "react-router-dom";
import { apiEndPoints } from "../constants/apiEndPoints";
import { message, Form, Button, Input, Spin } from "antd";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const initialUserData = {
	first_name: "",
	last_name: "",
	email: "",
	phone_number: "",
	password: "",
	confirm_password: "",
};

export default function SignUp() {
	const [userData, setUserData] = useState(initialUserData);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const capitalizeFirstLetter = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	};

	const onFinish = async (values) => {
		const capitalizedFirstName = capitalizeFirstLetter(values.first_name);
		const capitalizedLastName = capitalizeFirstLetter(values.last_name);
		setLoading(true);
		try {
			const response = await axios.post(apiEndPoints.userRegister, {
				first_name: capitalizedFirstName,
				last_name: capitalizedLastName,
				email: values.email,
				phone_number: values.phone_number,
				password: values.password,
				confirm_password: values.confirm_password,
			});

			if (response.data.message) {
				message.info(response.data.message);
				navigate("/login");
			}
			setUserData(initialUserData);
		} catch (error) {
			console.error("Error occurred:", error);

			if (
				error.response &&
				error.response.data &&
				error.response.data.message
			) {
				message.error(error.response.data.message);
			}
		} finally {
			setLoading(false);
		}
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	const handleChange = (event) => {
		const { id, value } = event.target;
		setUserData((prevData) => ({
			...prevData,
			[id]: value,
		}));
	};

	return (
		<section className="login-in-page sign-up-page">
			<div className="login-in-page-bg sign-up-page-bg ">
				<div className="row ">
					<div className="col-md-6 text-center signup-img-sec ">
						<div className="login-in-detail text-white">
							<div className="top">
								<h1>
									<span>Invoice Extraction</span>
								</h1>
							</div>
							<div className="img">
								<img
									src="Images/invoice1.jpg"
									className="img-fluid mb-4"
									alt="logo"
								/>
							</div>
							<div>
								<p>
									Feel free to upload the invoice image, and once completed,
									you'll swiftly receive the processed data and files at your
									disposal.
								</p>
							</div>
						</div>
					</div>
					<div className="col-md-6 position-relative">
						<div className="sign-in-from">
							<h3 className="mb-4 text-center">Register </h3>
							<h6 className="mb-0 h6-head">Sign Up For MediScan</h6>

							<Form
								className="mt-4"
								initialValues={initialUserData}
								onFinish={onFinish}
								onFinishFailed={onFinishFailed}>
								<div className="row">
									<div className="col-md-6">
										<Form.Item
											name="first_name"
											label="First Name"
											rules={[
												{
													required: true,
													message: "Please input your first name!",
												},
												{
													pattern: new RegExp("^[a-zA-Z]+$"),
													message: "Field only accepts alphabets",
												},
											]}>
											<Input type="text" placeholder="Enter First Name" />
										</Form.Item>
									</div>
									<div className="col-md-6">
										<Form.Item
											name="last_name"
											label="Last Name"
											rules={[
												{
													required: true,
													message: "Please input your last name!",
												},
												{
													pattern: new RegExp("^[a-zA-Z]+$"),
													message: "Field only accepts alphabets",
												},
											]}>
											<Input type="text" placeholder="Enter Last Name" />
										</Form.Item>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<Form.Item
											name="email"
											label="Email Address"
											rules={[
												{ required: true, message: "Please input your email!" },
												{
													type: "email",
													message: "The input is not a valid email address!",
												},
											]}>
											<Input type="email" placeholder="Enter Email" />
										</Form.Item>
									</div>
									<div className="col-md-6">
										<Form.Item
											name="phone_number"
											label="Contact Number"
											rules={[
												{
													required: true,
													message: "Please input your phone number!",
												},
												{
													pattern: /^[0-9]{10}$/,
													message:
														"Please enter a valid 10-digit phone number!",
												},
											]}>
											<Input type="text" placeholder="Enter Phone Number" />
										</Form.Item>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<Form.Item
											name="password"
											label="Password"
											rules={[
												{
													required: true,
													message: "Please input your password!",
												},
											]}>
											<Input.Password
												type="password"
												placeholder="Enter Password"
											/>
										</Form.Item>
									</div>
									<div className="col-md-6">
										<Form.Item
											name="confirm_password"
											label="Confirm Password"
											rules={[
												{
													required: true,
													message: "Please confirm your password!",
												},
											]}>
											<Input.Password
												type="password"
												placeholder="Confirm Password"
											/>
										</Form.Item>
									</div>
								</div>

								<div className="d-flex justify-content-center w-100 align-items-center mt-2 mb-4 signup-btn">
									<Button
										type="primary"
										htmlType="submit"
										className="btn btn-primary float-end p-0 ">
										{loading ? <Spin /> : "Sign Up"}
									</Button>
								</div>
								<div className="sign-info mt-4">
									<span className="dark-color d-inline-block line-height-2 mt-4">
										Already Have Account ?
										<NavLink to="/login" className="mx-2">
											Log In
										</NavLink>
									</span>
								</div>
							</Form>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
