import React, { useState, useEffect } from "react";
import "./Login.css";
import { NavLink } from "react-router-dom";
import InputField from "../Component/InputField";
import { apiEndPoints } from "../constants/apiEndPoints";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Form, Input, Button, message, Checkbox, Spin } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

import Cookies from "js-cookie";

const initialUserData = {
	email: "",
	password: "",
};
export default function Login({ setLoggedIn }) {
	const [userData, setUserData] = useState(initialUserData);
	const [rememberUser, setRememberUser] = useState(
		localStorage.getItem("isRememberMe") === "true" ? true : false
	);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const savedEmail = localStorage.getItem("email");
		const savedPassword = localStorage.getItem("password");

		if (savedEmail && savedPassword) {
			setUserData({
				email: savedEmail,
				password: savedPassword,
			});
			setRememberUser(true);
		} else {
			setUserData({
				email: savedEmail,
				password: savedPassword,
			});
		}
	}, []);
	const navigate = useNavigate();
	const handleChange = (event) => {
		const { id, value } = event.target;
		setUserData((prevData) => ({
			...prevData,
			[id]: value,
		}));
	};
	const handleLoginSuccess = (token) => {
		localStorage.setItem("token", token);
		sessionStorage.setItem("loggedIn", "true");
		setLoggedIn(true);
		navigate("/input-file");
	};

	const handleSubmit = async (values) => {
		setLoading(true);
		try {
			const response = await axios.post(apiEndPoints.userLogin, {
				email: values.email,
				password: values.password,
				remember_me: rememberUser,
			});
			if (response.data.message) {
				message.info(response.data.message);
				handleLoginSuccess(response.data.token);
				if (rememberUser) {
					localStorage.setItem("email", values.email);
					localStorage.setItem("password", values.password);
					localStorage.setItem("isRememberMe", rememberUser);
				} else {
					// localStorage.clear();
					// localStorage.remove("password");
					localStorage.remove("isRememberMe");
				}
			}
			console.log(response);
		} catch (error) {
			console.error("Error occurred:", error);
			if (
				error.response &&
				error.response.data &&
				error.response.data.message
			) {
				message.error(error.response.data.message);
			}
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<section className="login-in-page">
				<div className="login-in-page-bg ">
					<div className="row d-flex">
						<div className="col-md-6 text-center login-img-sec">
							<div className="login-in-detail text-white">
								<div className="top">
									<h1>
										<span>Invoice Extraction</span>
									</h1>
								</div>
								<div className="img">
									<img
										src="Images/invoice1.jpg"
										className="img-fluid mb-4"
										alt="logo"
									/>
								</div>
								<div>
									<p>
										Feel free to upload the invoice image, and once completed,
										you'll swiftly receive the processed data and files at your
										disposal.
									</p>
								</div>
							</div>
						</div>
						<div className="col-md-6 position-relative">
							<div className="login-in-from">
								<h1 className="mb-0">Sign in</h1>

								<Form
									className="mt-4"
									onFinish={handleSubmit}
									// initialValues={userData}
								>
									<Form.Item
										name="email"
										label="Email Address"
										rules={[
											{ required: true, message: "Please input your email!" },
											{
												type: "email",
												message: "The input is not a valid email address!",
											},
										]}>
										<Input
											type="email"
											prefix={<UserOutlined className="site-form-item-icon" />}
											placeholder="Enter email"
											value={userData.email}
											size="large"
											onChange={console.log("text")}
											// defaultValue={userData.email}
										/>
									</Form.Item>
									<Form.Item
										name="password"
										label="Password"
										rules={[
											{
												required: true,
												message: "Please input your password!",
											},
										]}>
										<Input.Password
											prefix={<LockOutlined className="site-form-item-icon" />}
											size="large"
											placeholder="Enter Password"
											value={userData.password}
										/>
									</Form.Item>
									<div className="d-flex justify-content-between">
										<Form.Item name="remember" valuePropName="checked">
											{/* <Checkbox
												defaultChecked={rememberUser}
												onChange={(e) => setRememberUser(e.target.checked)}>
												Remember me
											</Checkbox> */}
										</Form.Item>
										<Form.Item>
											<div className="forgot_sec ">
												<div className="float-end">
													<NavLink to="/ForgotPas">Forgot password?</NavLink>
												</div>
											</div>
										</Form.Item>
									</div>
									<Form.Item className="button-login">
										<Button
											type="primary"
											htmlType="submit"
											className="float-none ">
											{loading ? <Spin /> : "Log In"}
										</Button>
									</Form.Item>

									<div className="login-info mt-4">
										<span className="dark-color d-inline-block line-height-2 mt-2">
											Don't have an account?
											<NavLink to="/SignUp" className="mx-2">
												Sign-up
											</NavLink>
										</span>
									</div>
								</Form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

// import React, { useState, useEffect } from "react";
// import "./Login.css";
// import { NavLink } from "react-router-dom";
// import InputField from "../Component/InputField";
// import { apiEndPoints } from "../constants/apiEndPoints";

// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import { Form, Input, Button, message, Checkbox, Spin } from "antd";

// const initialUserData = {
// 	email: "",
// 	password: "",
// };

// export default function Login({ setLoggedIn }) {
// 	const [userData, setUserData] = useState(initialUserData);
// 	const [rememberUser, setRememberUser] = useState(false);
// 	const [loading, setLoading] = useState(false);

// 	useEffect(() => {
// 		const savedEmail = localStorage.getItem("email");
// 		const savedPassword = localStorage.getItem("password");

// 		if (savedEmail) {
// 			setUserData((prevData) => ({
// 				...prevData,
// 				email: savedEmail,
// 				password: savedPassword || "",
// 			}));
// 			setRememberUser(true);
// 		}
// 	}, []);

// 	const navigate = useNavigate();

// 	const handleChange = (event) => {
// 		const { id, value } = event.target;
// 		setUserData((prevData) => ({
// 			...prevData,
// 			[id]: value,
// 		}));
// 	};

// 	const handleLoginSuccess = (token) => {
// 		localStorage.setItem("token", token);
// 		sessionStorage.setItem("loggedIn", "true");
// 		setLoggedIn(true);
// 		navigate("/input-file");
// 	};

// 	const handleSubmit = async (values) => {
// 		setLoading(true);
// 		try {
// 			const response = await axios.post(apiEndPoints.userLogin, {
// 				email: values.email,
// 				password: values.password,
// 				remember_me: rememberUser,
// 			});
// 			if (response.data.message) {
// 				message.info(response.data.message);
// 				handleLoginSuccess(response.data.token);

// 				if (rememberUser) {
// 					localStorage.setItem("email", values.email);
// 					localStorage.setItem("password", values.password);
// 				} else {
// 					localStorage.removeItem("email");
// 					localStorage.removeItem("password");
// 				}
// 			}
// 		} catch (error) {
// 			console.error("Error occurred:", error);
// 			if (
// 				error.response &&
// 				error.response.data &&
// 				error.response.data.message
// 			) {
// 				message.error(error.response.data.message);
// 			}
// 		} finally {
// 			setLoading(false);
// 		}
// 	};

// 	return (
// 		<>
// 			<section className="login-in-page">
// 				<div className="login-in-page-bg ">
// 					<div className="row d-flex">
// 						<div className="col-md-6 text-center login-img-sec">
// 							<div className="login-in-detail text-white">
// 								<div className="top">
// 									<h1>
// 										<span>Invoice Extraction</span>
// 									</h1>
// 								</div>
// 								<div className="img">
// 									<img
// 										src="Images/invoice1.jpg"
// 										className="img-fluid mb-4"
// 										alt="logo"
// 									/>
// 								</div>
// 								<div>
// 									<p>
// 										Feel free to upload the invoice image, and once completed,
// 										you'll swiftly receive the processed data and files at your
// 										disposal.
// 									</p>
// 								</div>
// 							</div>
// 						</div>
// 						<div className="col-md-6 position-relative">
// 							<div className="login-in-from">
// 								<h1 className="mb-0">Sign in</h1>

// 								<Form
// 									className="mt-4"
// 									onFinish={handleSubmit}
// 									defaultValue={userData.email}
// 									// initialValues={userData}
// 								>
// 									<Form.Item
// 										name="email"
// 										label="Email Address"
// 										rules={[
// 											{ required: true, message: "Please input your email!" },
// 											{
// 												type: "email",
// 												message: "The input is not a valid email address!",
// 											},
// 										]}>
// 										<InputField
// 											id="email"
// 											type="email"
// 											placeholder="Enter email"
// 											value={userData.email}
// 											onChange={handleChange}
// 										/>
// 									</Form.Item>
// 									<Form.Item
// 										name="password"
// 										defaultValue={userData.password}
// 										label="Password"
// 										rules={[
// 											{
// 												required: true,
// 												message: "Please input your password!",
// 											},
// 										]}>
// 										<Input.Password
// 											id="password"
// 											placeholder="Enter Password"
// 											value={userData.password}
// 											onChange={handleChange}
// 										/>
// 									</Form.Item>
// 									<div className="d-flex justify-content-between">
// 										<Form.Item name="remember" valuePropName="checked">
// 											<Checkbox
// 												onChange={(e) => setRememberUser(e.target.checked)}
// 												checked={rememberUser}>
// 												Remember me
// 											</Checkbox>
// 										</Form.Item>
// 										<Form.Item>
// 											<div className="forgot_sec ">
// 												<div className="float-end">
// 													<NavLink to="/ForgotPas">Forgot password?</NavLink>
// 												</div>
// 											</div>
// 										</Form.Item>
// 									</div>
// 									<Form.Item className="button-login">
// 										<Button
// 											type="primary"
// 											htmlType="submit"
// 											className="float-none ">
// 											{loading ? <Spin /> : "Log In"}
// 										</Button>
// 									</Form.Item>

// 									<div className="login-info mt-4">
// 										<span className="dark-color d-inline-block line-height-2 mt-2">
// 											Don't have an account?
// 											<NavLink to="/SignUp" className="mx-2">
// 												Sign-up
// 											</NavLink>
// 										</span>
// 									</div>
// 								</Form>
// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 			</section>
// 		</>
// 	);
// }
